<template>
  <div>
    <h3 class="title-g">公共消息</h3>

    <el-tabs v-model="activeName">
      <el-tab-pane label="公共消息" name="1">
        <messagelist :custId="'public'" :custType="'admin'" />
      </el-tab-pane>
      <el-tab-pane label="新人消息" name="2">
        <el-tabs v-model="activeName2" @tab-click="handleClick">
          <el-tab-pane label="用户" name="0"></el-tab-pane>
          <el-tab-pane label="景区商家" name="1"></el-tab-pane>
        </el-tabs>

        <el-form ref="messageEidt" size="mini" class="databox-g" style="width: 50%">
          <el-form-item label="标题">
            <el-input v-model="messageEidt.title" placeholder="标题"></el-input>
          </el-form-item>
          <el-form-item label="内容" prop="content">
            <el-input type="textarea" v-model="messageEidt.content" :autosize="{ minRows: 5 }"></el-input>
          </el-form-item>
          <el-form-item>
            <el-form-item label="图片" prop="img">
              <el-upload
                v-show="!messageEidt.img"
                class="avatar-uploader"
                action="https://server.solar960.com/api/fileUpload"
                :headers="headerObj"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :multiple="false"
                :before-upload="beforeAvatarUpload"
              >
                <div class="imgBox">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </el-upload>
              <div class="imgBox" v-show="messageEidt.img" v-if="messageEidt.img">
                <img :src="'https://server.solar960.com' + messageEidt.img" width="100%" />
                <div class="image-preview-action" @click.stop="handleRemove()">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </el-form-item>
            <el-button type="primary" @click="submitForm()">保存</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="创建消息" name="3">
        <div class="btnwrap-g">
          <div>
            <label>发送对象</label>
            <el-radio v-model="radio" label="all">所有人</el-radio>
            <el-radio v-model="radio" label="user">所有用户</el-radio>
            <el-radio v-model="radio" label="sightCompany">景区商家</el-radio>
          </div>
          <br />
        </div>
        <messagecreate :custId="radio" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import messagecreate from "../components/MessageCreat";
import messagelist from "../components/MessageList";
import { getMessageTemplate, editMessageTemplate, deleteFile } from "@/api/api";
export default {
  components: {
    messagecreate,
    messagelist
  },
  data() {
    return {
      activeName: "1",
      activeName2: 0,
      headerObj: {
        Accept: "application/x.server.v1+json",
        Authorization: sessionStorage.getItem("Authorization")
      },
      radio: "all",
      messages: [],
      messageInfo: {},
      messageEidt: {}
    };
  },
  mounted() {
    this.getMessageTemplateFn();
  },
  methods: {
    handleRemove() {
      deleteFile({
        file: this.messageEidt.img
      }).then(res => {
        console.log(res);
      });
      this.messageEidt.img = "";
    },
    getMessageTemplateFn() {
      const that = this;
      getMessageTemplate().then(res => {
        if (res.result) {
          that.messages = res.data;
          that.messageEidt = res.data[that.activeName2];
        }
      });
    },

    handleClick(e) {
      this.messageEidt = this.messages[e.name];
      this.activeName2 = e.name;
    },
    submitForm() {
      const that = this;
      if (that.messageEidt.title == "") {
        alert("标题不能为空");
        return;
      }
      if (that.messageEidt.img == "" && that.messageEidt.content == "") {
        alert("图片和文字不能同时为空！");
        return;
      }

      this.$confirm("确定修改消息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          editMessageTemplate(that.messageEidt).then(res => {
            if (res.result) {
              that.$message({
                type: "success",
                message: "编辑成功"
              });
              that.getMessageTemplateFn();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消"
          });
        });
    },
    handleAvatarSuccess(res) {
      console.log(res);
      this.messageEidt.img = res.data.files;
    },
    beforeAvatarUpload(file) {
      //限制格式
      const isJPG =
        (file.type === "image/jpg") |
        (file.type === "image/png") |
        (file.type === "image/jpeg");

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG、JPEG 或 PNG 格式!");
      }
      return isJPG;
    }
  }
};
</script>

<style scoped>
label {
  margin-right: 50px;
}
.imgBox {
  width: 100%;
  position: relative;
  border: 1px dashed #cecece;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #cecece;
}
.imgBox img {
  width: 100%;
  position: relative;
}
.imgBox:hover .image-preview-action {
  display: flex;
}

.image-preview-action {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
  cursor: pointer;
  text-align: center;
  display: none;
  justify-content: center;
  align-items: center;
}

.image-preview-action .el-icon-delete {
  font-size: 32px;
}
</style>